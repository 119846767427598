import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"

const Media = ({ data }) => {
  const { allMarkdownRemark } = data
  const { frontmatter } = allMarkdownRemark.edges[0].node;
  const { title, mediaLink } = frontmatter;

  return (
    <Layout>
      <SEO
        title="Media"
        keywords={[`writer`, `rachel gerry`, `essays`, `critical theory`, `Toronto`]}
      />
      <div className="rightAlign">
        <h2>
          {title}
        </h2>
        {mediaLink.map(({ outlet, publication, title, embed, includeEmbed, linkApple, linkSpotify }, index) => {
          return (
            <Container key={index}>
              <h3>{title}</h3>
              <p>{publication} — {outlet}
              {(linkApple || linkSpotify) && <br/>}
              {linkApple && <a href={linkApple} target="_blank" rel="noreferrer">Apple Podcasts</a>}
              {linkApple && linkSpotify && <span> | </span>}
              {linkSpotify && <a href={linkSpotify} target="_blank" rel="noreferrer">Spotify</a>}
              </p>
              
             {includeEmbed && (
                <Video>
                    <div dangerouslySetInnerHTML={{__html: embed.code}}></div>
                </Video>
             )}
            </Container>
          )
        })}
    </div>
    </Layout>
  )
}
export default Media

const Video = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const Container = styled.div`
  margin-bottom: 32px;
  & h3 {
    margin-bottom: 8px;
  }
`

export const pageQuery = graphql`
  query MediaPageQuery {
    allMarkdownRemark(filter: {frontmatter: {path: {eq: "/media"}}}) {
      edges {
        node {
          frontmatter {
            title
            path
            mediaLink {
              outlet
              publication
              title
              embed {
                code
              }
              includeEmbed
              linkApple
              linkSpotify
            }
          }
        }
      }
    }
  }
`;